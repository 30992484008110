const template = `<div class="faceted-overlay">
<div class="overlay__inner">
<div class="overlay__content"><span class="spinner"></span></div>
</div>
</div>`;

function show() {
  if ($('.faceted-overlay').length === 1) {
    return;
  }

  $('body').append(template);
}

function hide() {
  $('.faceted-overlay').remove();
}

export {
  show as showOverlay,
  hide as hideOverlay,
};
