export class SubmenuCategories {

  constructor() {
    this.init();
  }

  init() {
    document.addEventListener('mouseover', (e) => {
      if (e.target.dataset.categoryMenuImage) {
        document.querySelector('.js-submenu-img-bg').style.backgroundImage = `url("${e.target.dataset.categoryMenuImage}")`;
      } else if (e.target.closest('[data-category-menu-image]')) {
        document.querySelector('.js-submenu-img-bg').style.backgroundImage = `url("${e.target.closest('[data-category-menu-image]').dataset.categoryMenuImage}")`;
      }
    });

    document.addEventListener('mouseout', (e) => {
      if (e.target.dataset.categoryMenuImage || e.target.closest('[data-category-menu-image]')) {
        document.querySelector('.js-submenu-img-bg').style.backgroundImage = '';
      }
    });
  }
}
