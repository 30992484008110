export class SocialShare {
  constructor() {
    this.shareWrappers = document.querySelectorAll('.js-share-wrapper');

    if (this.shareWrappers.length) {
      this.init();
    }
  }

  init() {
    this.shareWrappers.forEach((wrapper) => {
      const shareTrigger = wrapper.querySelector('.js-share-trigger');

      shareTrigger.addEventListener('click', () => {
        if (navigator.share) {
          this.displayNativeSharer(shareTrigger);
        } else {
          this.toggleCustomSharer(shareTrigger);
        }
      });
    });
  }

  displayNativeSharer(shareTrigger) {
    const { shareTitle, shareText, shareUrl } = shareTrigger.dataset;

    navigator.share({
      title: shareTitle,
      text: shareText,
      url: shareUrl,
    }).then(() => {
      console.log('Successful share');
    }).catch((error) => {
      console.log(error);
    });
  }

  toggleCustomSharer(shareTrigger) {
    const wrapper = shareTrigger.closest('.js-share-wrapper');
    const shareButtons = wrapper.querySelector('.js-share-buttons-rs');

    if (shareButtons.classList.contains('displayed')) {
      shareButtons.classList.remove('displayed');
    } else {
      shareButtons.classList.add('displayed');
    }
  }
}
