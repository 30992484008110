import axios from "axios";
import {showOverlay, hideOverlay} from './overlay';

export class RecipeFilter {
  constructor() {
    this.recipeListing = document.querySelector('.js-recipe-listing');
    this.recipeListTop = document.querySelector('.js-recipe-list-top');
    this.recipeFilters = document.querySelectorAll('.js-recipe-filters');
    this.recipeFilterForm = document.querySelector('.js-recipe-filters form');

    if (this.recipeListing && this.recipeListTop && this.recipeFilters.length && this.recipeFilterForm) {
      this.init();
    }
  }

  init() {
    this.recipeFilters.forEach((filtersWrapper) => {
      filtersWrapper.addEventListener('submit', (e) => e.preventDefault());
      filtersWrapper.addEventListener('change', (e) => this.onChange(e));
      filtersWrapper.addEventListener('click', (e) => this.onClick(e));
    });

    this.recipeListTop.addEventListener('change', (e) => this.onChange(e));

    this.formAction = this.recipeFilterForm.getAttribute('action');

    window.onpopstate = (e) => this.onPopState(e);
  }

  resetSearch() {
    // Reset is just a search without parameters
    this.doSearch([], true);
  }

  uncheckFilter(filter) {
    const filterInputs = this.recipeFilterForm.querySelectorAll('.js-filter-input');

    if (filterInputs.length) {
      filterInputs.forEach((input) => {
        if (input.dataset.filterType === filter) {
          input.checked = false;
          this.onChange();
        }
      });
    }
  }

  onClick(event) {
    if (event.target.classList.contains('js-search-reset') || event.target.closest('.js-search-reset')) {
      showOverlay();
      this.resetSearch();
    }

    if (event.target.classList.contains('js-active-filter') || event.target.closest('.js-active-filter')) {
      const filterType = event.target.dataset.filterType ?? event.target.closest('.js-active-filter').dataset.filterType;

      this.uncheckFilter(filterType);
    }
  }

  onChange() {
    showOverlay();
    const data = new FormData(this.recipeFilterForm);
    const params = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key of data.keys()) {
      if (key.endsWith('[]')) {
        params[key.substring(0, key.length - 2)] = data.getAll(key);
      } else {
        params[key] = data.get(key);
      }
    }

    //Order
    const orderSelect = this.recipeListTop.querySelector('.js-search-order');
    if (orderSelect) {
      params['order'] = orderSelect.value;
    }

    this.doSearch(params, true);
  }

  doSearch(params, pushState) {
    axios.get(this.formAction, {
      params,
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => {
      this.recipeListing.innerHTML = response.data.listing;
      this.recipeListTop.innerHTML = response.data.top;
      this.recipeFilters.forEach((filtersWrapper) => {
        filtersWrapper.innerHTML = response.data.filters;
      });
      this.recipeFilterForm = document.querySelector('.js-recipe-filters form');

      if (pushState) {
        this.pushState(response.request.responseURL, params);
        hideOverlay();
      }

      if (typeof response.data.alternative_urls !== "undefined")
      {
        for (const langCode in response.data.alternative_urls) {
          const langLink = document.querySelector(`.js-lang-selector-links a[data-lang-code="${langCode}"]`);

          if (langLink) {
            langLink.href = response.data.alternative_urls[langCode];
          }
        }
      }
    });
  }

  pushState(url, params) {
    window.history.pushState(params, null, `${url}`);
  }

  onPopState(event) {
    this.doSearch(event.state, false);
  }
}
