import { autocomplete } from '@algolia/autocomplete-js';

export class ElastiqueSearchBar {
  constructor() {
    this.timerId = undefined;

    document.querySelectorAll('.search-widget').forEach((element) => {
      autocomplete({
        container: element,
        placeholder: element.dataset.placeholder,
        onSubmit(params) {
          window.location.href = `${element.dataset.searchControllerUrl}?s=${params.state.query}`;
        },
        getSources: ({ query }) => this.doRequest(query, element.dataset.searchControllerUrl),
      });
    });
  }

  doRequest(query, endpointUrl) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    return new Promise((resolve) => {
      this.timerId = setTimeout(() => {
        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        resolve(
          fetch(`${endpointUrl}?s=${query}`, {
            headers: myHeaders,
          })
        );
      }, 300);
    }).then((response) => response.json())
      .then(this.getSource);
  }

  getSource(results) {
    return [
      {
        sourceId: 'elastique',
        getItems() {
          return results;
        },
        templates: {
          item({ item, html }) {
            if (!item.thumbnail) {
              item.thumbnail = prestashop.urls.no_picture_image.small.url;
            }
            return html`<div class="aa-ItemWrapper">
                          <a href="${item.url}" class="aa-ItemLink">
                            <div class="aa-ItemContent">
                              <div class="aa-ItemIcon">
                                <img
                                  src="${item.thumbnail}"
                                  alt="${item.name}"
                                  width="40"
                                  height="40"
                                />
                              </div>
                              <div class="aa-ItemContentBody">
                                <div class="aa-ItemContentTitle">
                                  ${item.title}
                                </div>
                                <div>
                                  ${item._index_name}
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>`;
          },
        },
      },
    ];
  }
}
