export class SearchBar {
  constructor() {
    this.header = document.querySelector('.js-header');
    this.headerSearchBar = document.querySelector('.js-header-search-bar');
    this.modalOverlay = document.querySelector('.js-modal-submenu-overlay');

    if (this.header && this.headerSearchBar && this.modalOverlay) {
      this.init();
    }
  }

  init() {
    this.header.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-search-bar-trigger') || e.target.closest('.js-search-bar-trigger')) {
        if (this.headerSearchBar.classList.contains('is-opened')) {
          this.closeSearchBar();
        } else {
          this.openSearchBar();
        }
      }

      if (e.target.classList.contains('js-searchbar-close') || e.target.closest('.js-searchbar-close')) {
        this.closeSearchBar();
      }
    });

    this.modalOverlay.addEventListener('click', () => {
      this.closeSearchBar();
    });

    document.addEventListener('keyup', (event) => {
      if (event.defaultPrevented) {
        return;
      }

      // If the property have an undefined value, we'll look for keyCode.
      const key = event.key || event.keyCode;

      if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.closeSearchBar();
      }
    });
  }

  openSearchBar() {
    const inputSearch = this.headerSearchBar.querySelector('input');

    this.headerSearchBar.classList.add('is-opened');
    this.modalOverlay.classList.add('active');
    document.body.classList.add('modal-open');
    this.header.classList.add('border-bottom');
    inputSearch.focus();
  }

  closeSearchBar() {
    this.headerSearchBar.classList.remove('is-opened');
    this.modalOverlay.classList.remove('active');
    document.body.classList.remove('modal-open');
    this.header.classList.remove('border-bottom');
  }
}
