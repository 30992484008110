export class ScrollTop {
  constructor() {
    this.scrollToTopTrigger = document.querySelector('.js-scroll-top');

    if (this.scrollToTopTrigger) {
      this.init();
    }
  }

  init() {
    this.scrollToTopTrigger.addEventListener('click', () => {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
}
