export class ScrollToAnchor {
  constructor() {
    this.scrollTriggers = document.querySelectorAll('.js-s2a-trigger');

    if (this.scrollTriggers.length) {
      this.init();
    }
  }

  init() {
    this.scrollTriggers.forEach((trigger) => {
      const { targetAnchor } = trigger.dataset;

      if (targetAnchor !== '' && typeof targetAnchor !== 'undefined') {
        let currentAnchor = null;
        const targetElements = document.querySelectorAll('.js-s2a-anchor');

        targetElements.forEach((element) => {
          const { anchor } = element.dataset;

          if (anchor === targetAnchor) {
            currentAnchor = element;
          }
        });

        trigger.addEventListener('click', () => {
          currentAnchor.scrollIntoView({
            behavior: 'smooth'
          })
        });
      }
    });
  }
}
